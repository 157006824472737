import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

import {useGoldCardData} from './useGoldCardData';
import {HeaderElementIcon} from './icons/HeaderElementIcon';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    // background: 'rgba(0, 0, 0, 0.40)',
    background: 'rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.colors.yellow[700]}`,
    textTransform: 'uppercase',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  subHeader: {
    display: 'grid',
    gridAutoColumns: 'max-content',
    gridAutoFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    padding: theme.spacing(3, 3, 0, 3),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 0, 2)
    }
  },
  name: {
    ...extendedTypography.h3Desktop,
    color: theme.palette.colors.yellow['900'],

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.h3Mobile
    }
  },
  price: {
    position: 'relative',
    ...extendedTypography.h4SemiboldDesktop,
    color: theme.palette.common.white,

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.h4SemiboldMobile
    }
  },
  arrowRight: {transform: 'rotate(180deg)'},
  rootTabs: {
    display: 'grid',
    marginBottom: 9,
    padding: theme.spacing(0.5),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: theme.palette.colors.blue[800],
    cursor: 'pointer',
    justifyContent: 'center'
  },
  rootTabsActive: {
    backgroundColor: theme.palette.common.white
  },
  priceActive: {
    color: theme.palette.colors.blue[800]
  },
  m: {color: theme.palette.colors.yellow[900]}
}));

export const GoldCardHeader = ({
  product,
  currency,
  useWithTabs = false,
  activeTab,
  ...props
}) => {
  const classes = useStyles();
  const {productSellPrice, productName} = useGoldCardData({product, currency});

  if (useWithTabs) {
    return (
      <div
        className={clsx(classes.rootTabs, activeTab && classes.rootTabsActive)}
        {...props}
      >
        <Typography className={clsx(classes.name)}>{productName}</Typography>
        <Typography
          className={clsx(classes.price, activeTab && classes.priceActive)}
          suppressHydrationWarning
        >
          {productSellPrice}
          <span className={classes.m}>/M</span>
        </Typography>
      </div>
    );
  }

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.name}>{productName}</Typography>
      </div>
      <div className={classes.subHeader}>
        <HeaderElementIcon />
        <Typography className={classes.price} suppressHydrationWarning>
          {productSellPrice}
          <span className={classes.m}>&#47;M</span>
        </Typography>
        <HeaderElementIcon className={classes.arrowRight} />
      </div>
    </>
  );
};
