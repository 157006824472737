import {Typography} from '@material-ui/core';
import {Field} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import {NumberTextFieldFormAdapter} from 'src/componentsNext/formAdapters/NumberTextFieldFormAdapter';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

import {GoldCurrencyDropdown} from './GoldCurrencyDropdown';

const useStyles = makeStyles(theme => ({
  amountColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    textAlign: 'center',

    '& input': {
      fontSize: 20,
      textAlign: 'center'
    },

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1)
    }
  },
  amountLabel: {
    // minWidth: 52,
    minWidth: 'fit-content',
    display: 'grid',
    '& span:nth-child(1)': {
      ...extendedTypography.h4SemiboldDesktop,
      fontWeight: 700,
      lineHeight: 1,
      color: theme.palette.colors.yellow[600],

      [theme.breakpoints.down('xs')]: {
        fontSize: 20
      }
    },
    '& span:nth-child(2)': {
      fontFamily: 'Raleway',
      fontSize: 10,
      fontWeight: 700,
      letterSpacing: 0.1,
      textTransform: 'uppercase',
      color: theme.palette.common.white,

      [theme.breakpoints.down('xs')]: {
        fontSize: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 'fit-content'
    }
  },
  input: {
    paddingLeft: 4,
    paddingRight: 4
  }
}));

const Label = ({children, useCurrencyDropdown}) => {
  if (useCurrencyDropdown) {
    return <GoldCurrencyDropdown>{children}</GoldCurrencyDropdown>;
  }

  return children;
};

export const GoldAmountColumn = ({
  label,
  fieldProps,
  useCurrencyDropdown,
  inactive = false
}) => {
  const classes = useStyles();
  const isDownXs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <div className={classes.amountColumn}>
      <Label useCurrencyDropdown={useCurrencyDropdown}>
        <div className={classes.amountLabel}>
          <Typography suppressHydrationWarning component='span'>
            {label}
          </Typography>
          <Typography component='span'>amount</Typography>
        </div>
      </Label>

      <Field
        fullWidth
        variant='outlined'
        placeholder='0'
        label=''
        size='small'
        component={NumberTextFieldFormAdapter}
        autoComplete='off'
        InputProps={{
          color: 'secondary',
          className: clsx('gold', inactive && 'inactive'),
          classes: {
            input: classes.input
          }
        }}
        {...fieldProps}
      />
    </div>
  );
};
