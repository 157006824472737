import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  goldCardRoot: {
    height: 'fit-content',
    position: 'relative',
    borderRadius: 10,
    // overflow: 'hidden',
    width: '100%',
    maxWidth: 412,
    border: `1px solid ${theme.palette.colors.blue['800']}`,

    borderTop: `3px solid var(--Yellow-700, #FF9A02)`,
    borderRight: `1px solid var(--Yellow-700, #FF9A02)`,
    borderBottom: `3px solid var(--Yellow-700, #FF9A02)`,
    borderLeft: `1px solid var(--Yellow-700, #FF9A02)`
  },
  bgImage: {
    backgroundImage: 'url("/images/imagesNext/bg/goldCard.png")',
    opacity: 0.6,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundSize: '60%'
  },
  santaHatIcon: {
    content: '""',
    position: 'absolute',
    width: 74,
    height: 58,
    background: 'url("/images/imagesNext/decorations/christmasHat.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    top: -39,
    left: -20,
    zIndex: 99,

    [theme.breakpoints.down('sm')]: {
      height: 36,
      top: -25,
      left: -12
    }
  },
  presentsIcon: {
    content: '""',
    position: 'absolute',
    width: 118,
    height: 80,
    background: 'url("/images/imagesNext/decorations/presents.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    bottom: 0,
    right: 0,
    zIndex: 99,

    [theme.breakpoints.down('sm')]: {
      width: 88,
      height: 60
    }
  }
}));

export const GoldCard = ({
  children,
  background,
  showSantaIcon = false,
  showPresentsIcon = false
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.goldCardRoot}
      style={{
        background,
        backgroundSize: 'auto'
      }}
    >
      {showSantaIcon && <div className={clsx(classes.santaHatIcon)} />}
      {showPresentsIcon && <div className={clsx(classes.presentsIcon)} />}
      <div className={classes.bgImage} />
      {children}
    </div>
  );
};

GoldCard.propTypes = {
  product: PropTypes.object
};
