import {makeStyles} from '@material-ui/core/styles';

import {GameVersionsEnum} from 'src/core/enums';

import {GoldCard} from './GoldCard';
import {GoldCardContent} from './GoldCardContent';
import {GoldCardHeader} from './GoldCardHeader';

const useStyles = makeStyles(theme => ({
  cardsWrapper: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {},

    '& > div:last-child': {
      overflow: 'hidden'
    }
  }
}));

export const cardDataMap = {
  [GameVersionsEnum.OSRS]: {
    name: GameVersionsEnum.OSRS,
    bg: 'linear-gradient(180deg, rgba(1,25,23,0.88) 75%, rgba(10,111,66,1) 100%)',
    quickBuyOptions: [100, 250, 500, 1000]
  },
  [GameVersionsEnum.RS3]: {
    name: GameVersionsEnum.RS3,
    bg: 'linear-gradient(180deg, rgba(12,2,16,0.88) 75%, rgba(52,7,49,0.88) 100%)',
    quickBuyOptions: [1000, 2000, 3000, 5000]
  }
};

export const BuyGoldCards = ({products, currency}) => {
  const classes = useStyles();
  // const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  // const [activeTab, setActiveTab] = useState(0);

  // const handleTabClick = tabIndex => {
  //   setActiveTab(tabIndex);
  // };

  // if (isDownSm) {
  //   return (
  //     <BuyGoldCard>
  //       <GoldCardTabs
  //         activeTabIndex={activeTab}
  //         currency={currency}
  //         products={products}
  //         onClick={handleTabClick}
  //       />
  //       {products.map((product, index) => (
  //         <GoldCardContent
  //           hidden={activeTab !== index}
  //           key={`${index}-${product.name}`}
  //           product={product}
  //           currency={currency}
  //         />
  //       ))}
  //     </BuyGoldCard>
  //   );
  // }

  return (
    <div className={classes.cardsWrapper}>
      {products.map((product, index) => (
        <GoldCard
          key={index}
          background={cardDataMap[product.gameVersion].bg}
          // showPresentsIcon={cardDataMap[product.gameVersion].name === 'RS3'}
          // showSantaIcon={cardDataMap[product.gameVersion].name === 'OSRS'}
        >
          <GoldCardHeader product={product} currency={currency} />
          <GoldCardContent
            hideBuyOptions
            product={product}
            currency={currency}
            quickBuyOptions={cardDataMap[product.gameVersion].quickBuyOptions}
          />
        </GoldCard>
      ))}
    </div>
  );
};
